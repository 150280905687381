import { storeToRefs } from 'pinia'
import { useBookingStore } from '@/store/booking'

export function usePaymentCurrency() {
  const bookingStore = useBookingStore()
  const { paymentTxnAmount } = storeToRefs(bookingStore)

  const currencyCode = computed(() => bookingStore.paymentCurrencyCode)

  const txnAmountText = computed(() => (paymentTxnAmount.value >= 0 ? `${currencyCode.value} ${priceFormat(+paymentTxnAmount.value)}`.trim() : ''))

  return {
    currencyCode,
    paymentTxnAmount,
    txnAmountText,
  }
}
